/** 
 * 大数据首页
 */
<template>
  <v-card flat>
    <v-banner
      single-line
      height="400"
      class="banner-one"
      elevation="0"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/dataHomeBanner.png"
          height="100%"
          :transition="false"
        
          class="d-flex align-center"
       >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center align-center">
          <v-card color="transparent" flat width="80%" class="d-flex flex-no-wrap justify-center">
            <div >
              <div>
                <v-card-title class="banner-title white--text font-size-48 mb-6 pa-0 justify-center text-align-center" style="max-width:800px">
                  深入业务场景，数据驱动增长 
                </v-card-title>
                <v-card-title class="banner-text white--text justify-center px-8 pa-0 font-size-18">
                  营销分析，运营优化，用户增长，营销转化
                </v-card-title>
                <!-- <div class="btnWrap">
                    <p class="banner-btn">立即咨询</p>
                </div> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 数字赋能营销转化 -->
    <v-container style="max-width:1200px">
      <v-card elevation="0">
        <div class="public-title" style="margin-top:70px">
          <p>{{ data.wrap1.titleEng }}</p>
          <div>
            <span>{{ data.wrap1.title }}</span>
            <span>{{ data.wrap1.tip }}</span>
          </div>
        </div>
        <v-container>
          <v-row class="lavMaxWidth justify-center mt-8">
            <v-col cols="4"  v-for="(card,index) in data.wrap1.list" :key="index">
              <v-card elevation="0">
                <div class="d-flex justify-center"><v-img class="flex-grow-0" :src="card.src" width="120" height="120">
                  <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
            </v-img></div>
                <v-card-title class="justify-center font-size-24" v-text="card.title"></v-card-title>
                <v-card-text class="font-size-14 text-align-center" v-text="card.text" style="line-height:26px;padding:0px 55px;"></v-card-text>   
              </v-card>
          </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <!-- 应用场景 -->
    <v-container style="max-width:1200px">
      <v-card elevation="0">
        <div class="public-title" >
          <p>{{ data.wrap2.titleEng }}</p>
          <div>
            <span>{{ data.wrap2.title }}</span>
            <span>{{ data.wrap2.tip }}</span>
          </div>
        </div>
        <div class="d-flex justify-center mt-10">
          <v-card elevation="0" class="cardStyle">
            <v-tabs v-model="advantageTab" hide-slider vertical style="width:950px;">
              <v-tab
                v-for="(item, index) in data.wrap2.list"
                :key="index"
                :class="['mr-3 justify-center']"
                :style="{'background':advantageTab == index?'linear-gradient(135deg, #317BF8, #0024FF)':'#E9F0FF','width':'180px','height':'200px'}"
              >
         
                <v-card-title class="font-size-18 px-2" :style="{'color':advantageTab == index?'#FFF!important':'#1C56FB!important',}">{{
                  item.tabTitle
                }}</v-card-title>
              </v-tab>
              <v-tab-item
                v-for="(item,i) in data.wrap2.list"
                :key="i"
                transition="fade-transition"
              >
                <v-card style="box-shadow: none" height="400" width="779" class="d-flex align-center">
                  <div class="d-flex align-center pr-10">
                    <div class="d-flex flex-column">
                      <div class="pr-5">
                        <v-card-title
                          
                          class="font-size-24 font-weight-bold white-space-pre-wrap"
                          style="line-height:24px;color:#000000;"
                          v-text="item.childrenTitle"
                        ></v-card-title>
                        <v-card-text
                          class="font-size-14 white-space-pre-wrap"
                          style="color:#666666;width:300px"
                          v-text="item.content"
                        ></v-card-text>
                      </div>
                    </div>
                    <v-img
                      :src="item.src"
                      class="white--text align-left flex-grow-0"
                      width="400"
                      height="250"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            size="80"
                            color="red lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </div>
      </v-card>
    </v-container>   
     <!--全方位满足企业需求  -->
    <v-container class="max-width-1200">
      <div class="public-title" style="margin-top:66px">
          <p>BRAND HONOR</p>
          <div>
            <span>全方位满足企业需求</span>
            <span>深入业务场景分析</span>
          </div>
      </div>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-img src="https://h5.ophyer.cn/official_website/other/bigData3.png" height="420" contain>
          <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
            </template></v-img>
        </v-col>
      </v-row>
    </v-container>
     <!--整合应用  -->
    <v-container style="max-width:1200px" class="mb-10">
          <!-- <div class="swiper-text"  v-for="(item, index) in imgList" :key="index" v-show="imgIndex === index">
            <div>{{item.title}}</div>
            <div>{{item.text}}</div>
          </div> -->
        <div class="public-title swiper-text" style="margin-top:0px;height:unset;"  v-for="(item, index) in imgList" :key="index" v-show="imgIndex === index">
          <div>
            <span>{{item.title}}</span>
            <span>{{item.text}}</span>
          </div>
      </div>
      <div style="height:40px;"></div>
      <el-carousel :interval="4000" type="card" @change='linkTo' v-model="value">
        <el-carousel-item v-for="(item, index) in imgList" :key="index" ref="carousel" >
          <!-- <div class="swiper-text" v-if="imgIndex === index">
            <div>{{item.title}}</div>
            <div>{{item.text}}</div>
          </div> -->
          <!-- <div style="height:100px;background:#fff;width:10px;" v-else></div> -->
          <img :src='item.src' style="width:520px;object-fit:contain;" > 
        </el-carousel-item>
      </el-carousel>
    </v-container>
  </v-card>
</template>

<script>
import contentData from '@/static/contentData.js';
export default {
  name: "bigDataAnalysis",
  data (){
    return {
      value:0,
      mark:0,
      data:contentData.bigDataAnalysis,
      advantageTab: null,
      showDialog: false,
      model: 0,
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange',
      ],

      imgList:[
        {src:'https://h5.ophyer.cn/official_website/other/dataHome4-1.png',title:'整合应用',text:'支持文本数据及主流数据库，安全接入Mysql等多种数据库'},
        {src:'https://h5.ophyer.cn/official_website/other/dataHome4-2.png',title:'清洗数据',text:'清洗数据中错漏的数据，整合数据资源，规整数据格式'},
        {src:'https://h5.ophyer.cn/official_website/other/dataHome4-3.png',title:'多维分析',text:'多角度透析数据，一张图表释放更多分析结果，分析结果简单干练'},
        {src:'https://h5.ophyer.cn/official_website/other/dataHome4-4.png',title:'可视化效果库',text:'丰富的可视化效果库，炫酷呈现分析结果'},
        {src:'https://h5.ophyer.cn/official_website/other/dataHome4-5.png',title:'团队协作',text:'按角色分配数据资源，提升分析效率'}
      ],
      // swiperOption: {
      //   loop: true,
      //   slidesPerView: 3,
      //   spaceBetween: 30,
      //   // 自动播放
      //   // autoplay: {
      //   //   delay: 3000,
      //   //   stopOnLastSlide: false,
      //   //   disableOnInteraction: false,
      //   // },
      //   // 显示分页
      //   pagination: {
      //     el: ".swiper-pagination",
      //     clickable: true, //允许分页点击跳转
      //   },
      //   //监听事件
      //   // on: {
      //   //   slideChangeTransitionEnd: function (){
      //   //     console.log(this)
      //   //     that.imgIndex = this.realIndex
      //   //     ;
      //   //   }
      //   // },
      //   // 设置点击箭头
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev",
      //   },
      // },
      imgIndex: 0,
    }
  },
  mounted(){

  },
  methods: {
      // autoPlay () { 
      //   this.mark++; 
      //   if (this.mark === 4) { //当遍历到最后一张图片置零 
      //     this.mark = 0 
      //   } 
      // }, 
      // play () { 
      //   setInterval(this.autoPlay, 2500) 
      //   }, 
      // change (i) { 
      //   this.mark = i 
      // } ,
    chooseCard:function(index){
			this.$refs.carousel.setActiveItem(index);
		},
      linkTo(val){
        console.log(val,'11111')
        this.imgIndex = val
      }
    }, 
  }

</script>

<style lang="scss" scoped>
::v-deep .el-carousel__item--card {
  img{
    width: 400px;
    height: 260px;
    padding-left: 140px;
    padding-right: 20px;
  }
}
::v-deep .el-carousel__item--card is-in-stage{
  transform: translateX(-51px) scale(0.53);
}

.public-title{
  margin-bottom: 0px;
  height: unset;
}
.containerStyle{
  margin-top: 50px;
  .part-one{
    margin-bottom: 21px;
    img{
      border-radius: 10px;
      width: 600px;
      height: 300px;
      object-fit: contain;
    }
    .text-wrap{
      width: 600px;
      align-items: center;
      display: flex;
      .con_tit{
        font-size: 24px;
        color: #333333;
        font-weight: 500;
        line-height: 34px;
        margin-top: 40px;
      }
      .con_text{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        height: unset;
        margin-top: 16px;
      }
      .banner-btn {
        margin-top: 44px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.warp {
  max-width: 1200px;
  margin: 0 auto;
}

.warp4 {
  .con {
    margin-top: 45px;
    .box-img {
      width: 240px;
      height: 160px;
      border-radius: 10px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

::v-deep .theme--light.v-sheet{
  background-color:unset
}

.cardStyle{
  border: 1px solid #BED3FF;
  border-radius: 30px;
  overflow: hidden;
  height: 400px;
  box-sizing: border-box;
}


.swiper-text{
  z-index: 99;
  color: #666666;
  font-size: 14px;
  bottom: -10px;
  :nth-child(1){
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    line-height: 44px;
    text-align: center;
  }
  :nth-child(2){
    // width: 627px;
    height: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    text-align: center;
  }
}

.swiper-container {
  height: 500px;
  img {
    width: 100%;
  }
}
 
.swiper-slide{  
  width: 355.333px; 
  // margin-right: 30px;
  text-align: center;
  font-size: 18px;
  background: #fff;
  // display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.6);
  padding-top:60px
}
.swiper-slide-next{
  transform: scale(1.1);
  padding-top: 0px;
  margin-top: 20px;
  margin-right: 0px;
}

.swiper-slide-active{
  padding-top: 60px;
}

::v-deep .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 60px;
}

</style>